import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import image1 from "./../images/IMG_1397.jpg"
import image2 from "./../images/20171122_151855.jpg"
import Girls from "./../images/IMG_20170909_101257.jpg"
import Education from "./../images/education.jpg"
import Drafting from "./../images/drafting.jpg"
import Games from "./../images/games.jpg"
import Connection from "./../images/connection.jpg"
import Community from "./../images/community.jpg"
import Provision from "./../images/provision.jpg"
import Special from "./../images/special.jpg"
import Midwives from "./../images/what-we-do/midwives.jpg"
import Communication from "./../images/what-we-do/communication.jpg"
import Tailoring from "./../images/what-we-do/tailoring.jpg"
import Ultasound from "./../images/what-we-do/ultasound.jpg"

import OrientationGahini from "./../images/2024/orientation-gahini/2.jpg"
import MusanzeTraining from "./../images/2024/musanze-team/3.jpg"

const WhatWeDo = () => (
  <Layout>
    <SEO title="What we do" />
    <section
      className="section-header text-white overlay-soft-primary"
      style={{
        backgroundImage: "url(" + Education + ")",
        backgroundColor: "#cccccc",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div className="container">
        <div className="row min-vh-50 justify-content-center text-center align-items-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">What we do</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="section section-lg pt-6 bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 text-center">
            <h4 className="h2 font-weight-bolder">Our Projects & Services</h4>
          </div>
        </div>

        <div className="row align-items-center pb-4">
          <div className="col-12 order-md-2">
            <ol className="list-decimal">
              <li>To support the Rwanda Biomedical Center to conduct operations research</li>
              <li>Designing program for use of telemedicine to increase access to health services.</li>
              <li>Build the capacity of in services health care providers (nurses, midwives & medical doctors) and
                health facility managers.
              </li>
              <li>Improved digital health literacy for health care providers; nurses and midwives working in rural
                public health areas.
              </li>
              <li>Conduct capacity building for health providers working in public health facilities in provision
                Quality SRH services.
              </li>
              <li>Support in the development of Sexual reproductive health services delivery guidelines.</li>
              <li>To design and implement program for integration of comprehensive reproductive health.</li>
              <li>Support the provision of comprehensive sexuality education (CSE) to adolescents and youth visiting
                public youth centers and primary health facilities.
              </li>
              <li>Strengthen the provision of comprehensive youth friendly sexual reproductive health service in youth
                centers and youth corners.
              </li>
              <li>Telemedicine project implementation; Remotely connect Nurse and midwives working rural health centers
                to doctors in district hospitals to improve access to Sexual reproductive health services.
              </li>
              <li>Strengthen the capacity of Isange One Stop Center (GBV Centers) in the provision of integrated sexual
                reproductive health services.
              </li>
              <li>Strengthen the provision of post abortion care contraceptive methods.</li>
              <li>To use Information and communications technology (ICT) Platform in SRH education; including social
                media platform.
              </li>
              <li>Organize training for nurses and midwives to provide basics knowledge and skills to the use of
                ultrasound machine.
              </li>
              <li>Support district health unit/public health facilities to implement strategies that aim to improve the
                health of women during pregnancy and childbirth.
              </li>
              <li>To provide technical and financial to conduct training of health providers in provision of
                comprehensive abortion services.
              </li>
              <li>Where needed supply a stock of SRH commodities to public health facilities and public youth centers.
              </li>
              <li>To build the capacity of rural public health facilities and family planning health posts in provision
                of quality Sexual reproductive health services.
              </li>
              <li>Works in collaboration with healthcare professionals; SRHR champions, Nursing and midwives council,
                Society of obstetricians and gynecology to address the barriers to access to SRH services.
              </li>
              <li>Implement support groups and empowerment of vulnerable groups of adolescents and young people
                including teen mothers.
              </li>
              <li>Support public youth center and youth clubs to mobilize adolescents and young people to use sexual and
                reproductive health services.
              </li>
              <li>Organize recreation activities in youth centers and free wireless connection for sexual reproductive
                health education.
              </li>
              <li>In public youth centers; organize special day/ events for sexual reproductive education for
                adolescents and young people; special time to girls, boys, teen mothers, and sex workers.
              </li>
              <li>Conduct outreach campaigns in health education and services provision.</li>
              <li>To support vulnerable groups of youth to implement socio-economic strategies.</li>


            </ol>
          </div>
          <div className="col-12 col-md-2 mr-lg-auto">
            <img src="" className="w-100" alt=""/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={Provision} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={image2} className="img-fluid rounded"/>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img src={Girls} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Drafting} className="img-fluid rounded"/>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img src={Communication} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Tailoring} className="img-fluid rounded"/>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-md-6">
            <img src={Midwives} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Ultasound} className="img-fluid rounded"/>
          </div>
        </div>

        <div className="row py-4">
          <div className="col-md-6">
            <img src={Games} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Connection} className="img-fluid rounded"/>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img src={Community} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={Special} className="img-fluid rounded"/>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img src={OrientationGahini} className="img-fluid rounded"/>
          </div>
          <div className="col-md-6">
            <img src={MusanzeTraining} className="img-fluid rounded"/>
          </div>
        </div>

        <div className="row  align-items-center py-4 mb-5 mb-md-2">
          <div className="col-12 col-md-10">
            <h2 className="font-weight-bolder mb-4">EXPECTED OUTCOMES</h2>
            <ol className="list-decimal">
              <li>Reduced number of teenage pregnancies.</li>
              <li>Reduced rate of sexual transmitted disease.</li>
              <li>Reduced disabilities and death related to pregnancy.</li>
              <li>Evidence are generated to inform policy decision in sexual reproductive health.</li>
              <li>Improved access to services for survivors of sex violence.</li>
              <li>Increased knowledge on reproductive health among adolescents, young people and women’s .</li>
              <li>Adolescents and youth are empowered to make informed choices about their sexual health.</li>
              <li>Increased accessibility of sexual reproductive health services (SRH) among adolescents, youth and
                women.
              </li>
              <li>Information and Communication Technology (ICT) platform are used for an improved access to SRH
                services in rural areas.
              </li>
              <li>Expanded geographic coverage of reproductive health services including family planning services and
                comprehensive abortion services.
              </li>
              <li>Increased capacity of health providers in provision of youth SRH friendly services.</li>
              <li>Underserved populations reached with quality sexual reproductive services</li>
              <li>Reduced FP methods drop-out rates by encouraging LARCs FP methods.</li>
              <li>Reduced misinformation, myths, stigma and other barriers to SRHR services.</li>
              <li>Reduced number of girls and women doing unsafe abortion.</li>
              <li>Significant Improvement in social economic conditions among vulnerable group of young people; teen
                mothers and increase self-confidence and hope for the future
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default WhatWeDo
